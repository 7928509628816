import { useState, useEffect } from "react";
import styled from "styled-components";
import React from "react";
import FRAME from "../assets/images/FRAME.png";
import NFT from "../assets/gif/NFT.gif";
import BG from "../assets/images/BG.png";
import CONNECT from "../assets/images/CONNECT.png";
import MINT from "../assets/images/MINT.png";
import TAPE1 from "../assets/images/TAPE1.png";
import TAPE2 from "../assets/images/TAPE2.png";
import PLUS from "../assets/images/PLUS.png";
import MINUS from "../assets/images/MINUS.png";
import NOTOPEN from "../assets/gif/NOTOPEN.gif";
import RUGGED from "../assets/gif/RUGGED.gif";
import abi from "../assets/abi/abi.json";
import og from "../assets/addresses/og.json";
import wl from "../assets/addresses/wl.json";
import config from "../assets/config/config.json";
const ethers = require("ethers");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const ogNodes = og.map((addr) => keccak256(addr));
const ogTree = new MerkleTree(ogNodes, keccak256, { sortPairs: true });

const wlNodes = wl.map((addr) => keccak256(addr));
const wlTree = new MerkleTree(wlNodes, keccak256, { sortPairs: true });

const MinterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100wh;
  height: 100vh;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  @media (max-width: 860px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 800px;
  background-image: url(${FRAME});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1770px) {
    height: 600px;
    width: 600px;
  }
  @media (max-width: 1300px) {
    width: 350px;
    height: 350px;
  }
  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 510px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 360px) {
    width: 200px;
    height: 200px;
  }
`;

const Connect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
  width: 800px;
  @media (max-width: 1770px) {
    height: 500px;
    width: 500px;
  }
  @media (max-width: 1300px) {
    width: 350px;
    height: 350px;
  }
  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 510px) {
    width: 200px;
    height: 200px;
  }
`;

const MintRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

const IncBtns = styled.img`
  max-width: 70px;
  @media (max-width: 768px) {
    max-width: 40px;
  }
`;

const Total = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-top: 1rem;
  @media (max-width: 1300px) {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Available = styled.h1`
  font-size: 4rem;
  color: #fff;
  margin-top: 2rem;
  @media (max-width: 1300px) {
    font-size: 3rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ConnectDesc = styled.h1`
  font-size: 3rem;
  color: #fff;
  text-align: center;
  @media (max-width: 1300px) {
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const NotOpen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url(${NOTOPEN});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 999999999;
`;

const Rugged = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url(${RUGGED});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 999999999;
`;

const Minter = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [isMinted, setIsMinted] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [supply, setSupply] = useState(0);
  const [proof, setProof] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [isSalesOpen, setIsSalesOpen] = useState(true);
  const [balance, setBalance] = useState(0);

  const [IsOG, setIsOG] = useState(false);
  const [IsWL, setIsWL] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [signer, setSigner] = useState(null);

  const isActive = async () => {
    let result = await contract.ogOpen();
    setIsSalesOpen(result);
    console.log(result);
  };

  const connect = () => {
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            setIsConnected(true);
            accountChangedHandler(account[0]);
            getSupply();
          });
      }
    } else {
      setIsConnected(false);
    }
  };

  const accountChangedHandler = (account) => {
    setDefaultAccount(account);
    updateEthers();
  };

  const updateEthers = async () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempContract = new ethers.Contract(
      config.contractAddress,
      abi,
      tempSigner
    );
    setContract(tempContract);
  };

  const getOGProof = async () => {
    const proof = ogTree.getHexProof(keccak256(defaultAccount));
    setProof(proof);
    return proof;
  };

  const getWLProof = async () => {
    const proof = wlTree.getHexProof(keccak256(defaultAccount));
    setProof(proof);
    return proof;
  };

  const increaseMintAmount = () => {
    let tempMax = config.maxMint;
    let tempMintAmount = mintAmount + 1;
    if (tempMintAmount > tempMax) {
      tempMintAmount = tempMax;
    }
    setMintAmount(tempMintAmount);
  };

  const decreaseMintAmount = () => {
    let tempMintAmount = mintAmount - 1;
    if (tempMintAmount < 1) {
      tempMintAmount = 1;
    }
    setMintAmount(tempMintAmount);
  };

  const getSupply = async () => {
    let supply = await contract.totalSupply();
    setSupply(supply.toString());
  };

  const mint = async () => {
    if (balance >= 5) {
      alert("You cant mint more than 5 tokens in OG minting");
      window.location.reload();
    } else {
      let totalCost = config.cost * mintAmount;
      console.log(totalCost);
      await contract
        .mint(mintAmount, {
          value: ethers.utils.parseEther(totalCost.toString()),
          gasLimit: config.gasLimit,
        })
        .then((tx) => {
          setIsMinting(true);
          tx.wait()
            .then(() => {
              setIsMinting(false);
              setIsConnected(false);
            })
            .catch((err) => {
              alert("Minting failed!");
            });
        })
        .catch((err) => {
          setIsConnected(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    updateEthers();
  }, []);

  if (window.ethereum === undefined) {
    alert("Please install MetaMask!");
  }

  return (
    <>
      {isSalesOpen ? (
        <>
          {isMinting ? (
            <Rugged />
          ) : (
            <MinterContainer>
              <img
                src={TAPE1}
                alt="FRAME"
                style={{
                  height: "100%",
                }}
              />
              <Splitter>
                <Frame>
                  <img
                    src={NFT}
                    alt="NFT"
                    style={{ width: "80%", height: "80%" }}
                  />
                </Frame>
                <Connect>
                  {isConnected ? (
                    <>
                      <img
                        src={MINT}
                        style={{
                          width: "100%",
                        }}
                        onClick={mint}
                      />

                      <MintRow>
                        <IncBtns src={MINUS} onClick={decreaseMintAmount} />
                        <Total>{mintAmount}</Total>
                        <IncBtns src={PLUS} onClick={increaseMintAmount} />
                      </MintRow>
                      <Available>
                        {supply} / {config.maxSupply}
                      </Available>
                    </>
                  ) : (
                    <>
                      <img
                        src={CONNECT}
                        alt="CONNECT"
                        style={{ width: "100%" }}
                        onClick={connect}
                      />
                      <ConnectDesc>Connect your wallet</ConnectDesc>
                    </>
                  )}
                </Connect>
              </Splitter>
              <img
                src={TAPE2}
                alt="FRAME"
                style={{
                  height: "100%",
                }}
              />
            </MinterContainer>
          )}
        </>
      ) : (
        <>{isSalesOpen === null ? "" : <NotOpen />}</>
      )}
    </>
  );
};

export default Minter;
